import { FC, useRef } from 'react';
import { Button, Typography } from '@mui/material';
import { WalletIcon } from 'components/Icon/components';
import { COLOR_WHITE } from 'theme/colors';
import { BORDER_MAIN } from 'theme/variables';
import { shortenPhrase } from 'utils';

export interface AccountProps {
  onClick: () => void;
  address: string;
}

export const Account: FC<AccountProps> = ({ address, onClick }) => {
  const popoverRef = useRef(null);

  return (
    <Button
      ref={popoverRef}
      onClick={onClick}
      variant="text"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        textTransform: 'uppercase',
        alignItems: 'center',
        background: COLOR_WHITE,
        px: 2,
        py: 2.25,
        borderRadius: '12px',
        border: BORDER_MAIN,
      }}
    >
      <Typography className="l" mr={2}>
        {shortenPhrase(address, 6, 4)}
      </Typography>
      <WalletIcon />
    </Button>
  );
};
