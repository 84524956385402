import { apiV1BaseUrl as baseURL } from 'appConstants';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { disconnectWalletState } from 'store/user/reducer';
import userSelector from 'store/user/selectors';
import { validateStatus } from 'utils/validateStatus';

const client: AxiosInstance = axios.create({
  baseURL,
  validateStatus,
});

export default function* ajax(config: AxiosRequestConfig): Generator<SelectEffect | CallEffect | PutEffect> {
  const accessToken = yield select(userSelector.getProp('key'));

  if (accessToken) {
    client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let response: any;
  try {
    response = yield call<(configVar: AxiosRequestConfig) => Promise<AxiosResponse>>(client, config);
  } catch (err) {
    if (axios.isAxiosError(err) && accessToken && err.response?.status === 403) {
      yield put(disconnectWalletState());
    }
  }

  return response;
}
