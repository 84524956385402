import { Modules, RoutesProps } from 'types';

/**
@Description
key 'dirName' - should be equal to component dir name
@example MyInvestments - name of dir and dirName: MyInvestments
*/

export const routes: RoutesProps = {
  home: {
    dirName: 'Home',
    root: {
      id: 1,
      title: 'Login',
      path: '/',
      isNavItem: false,
      module: Modules.login,
      isProtected: false,
    },
  },
  select: {
    dirName: 'Select',
    root: {
      id: 2,
      title: 'Select',
      path: '/select',
      module: Modules.main,
      isProtected: true,
    },
  },
  createReport: {
    dirName: 'CreateReport',
    root: {
      id: 3,
      title: 'Create Report',
      path: '/create-report/:id/:dateFrom?/:dateTo?/:userAddress?/',
      getPath: (id: string, dateFrom?: string, dateTo?: string, userAddress?: string) =>
        `/create-report/${id}/${dateFrom ? `${dateFrom}/` : ''}${dateTo ? `${dateTo}/` : ''}${
          userAddress ? `${userAddress}/` : ''
        }
        `,
      module: Modules.main,
      isProtected: true,
    },
  },
  report: {
    dirName: 'Report',
    root: {
      id: 3,
      title: 'Report',
      path: '/report/:reportType/:dateFrom/:dateTo/:userAddress?/',
      getPath: (reportType: string, dateFrom: string, dateTo: string, userAddress?: string) =>
        `/report/${reportType}/${dateFrom}/${dateTo}/${userAddress ? `${userAddress}/` : ''}`,
      module: Modules.main,
      isProtected: true,
    },
  },
  staking: {
    dirName: 'Staking',
    root: {
      id: 4,
      title: 'Staking',
      path: '/staking',
      module: Modules.main,
      isProtected: true,
    },
  },
};
