import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { Modal, ModalProps } from 'components';
import { ExitIcon } from 'components/Icon/components';
import { FontWeights } from 'theme/Typography';

export interface DisconnectModalProps extends ModalProps {
  disconnect: () => void;
}

export const DisconnectModal: FC<DisconnectModalProps> = ({ disconnect, open, onClose }) => {
  const handleDisconnect = () => {
    disconnect();
    onClose();
  };
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} title={t('header.wallet')}>
      <Box sx={{ width: { xs: '100%', sm: '296px' } }}>
        <Button
          variant="contained"
          sx={{
            width: '100%',
            mt: 2,
            textTransform: 'none',
            fontWeight: FontWeights.fontWeightRegular,
          }}
          onClick={handleDisconnect}
        >
          {t('header.disconnect')}
          <ExitIcon sx={{ ml: 2 }} />
        </Button>
      </Box>
    </Modal>
  );
};
