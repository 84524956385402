/* eslint-disable camelcase */
import { URL } from 'appConstants';
import { LoginReq } from 'types/requests';

import ajax from './ajax';

export const baseApi = {
  getLoginMessage(params: { address: string }) {
    return ajax({
      method: 'get',
      url: URL.getMessageToSign,
      params,
    });
  },
  login(data: LoginReq) {
    return ajax({
      method: 'post',
      url: URL.login,
      data,
    });
  },
  getRewardReport(params: { page: string; per_page: string; start: string; end: string }) {
    return ajax({
      method: 'get',
      url: URL.getRewardReport,
      params,
    });
  },
  getRewardReportCSV(params: { start: string; end: string }) {
    return ajax({
      method: 'get',
      url: URL.getRewardReportCsv,
      params,
    });
  },
  getUserDadReport(params: { address: string; page: string; per_page: string; start: string; end: string }) {
    return ajax({
      method: 'get',
      url: URL.getDadRewardReport,
      params,
    });
  },
  getUserDadRewardCSV(params: { address: string; start: string; end: string }) {
    return ajax({
      method: 'get',
      url: URL.getDadRewardReportCsv,
      params,
    });
  },
  getUserSonsReport(params: { address: string; page: string; per_page: string; start: string; end: string }) {
    return ajax({
      method: 'get',
      url: URL.getSonsRewardReport,
      params,
    });
  },
  getUserSonsRewardCSV(params: { address: string; start: string; end: string }) {
    return ajax({
      method: 'get',
      url: URL.getSonsRewardReportCsv,
      params,
    });
  },
};
