import { createAction } from '@reduxjs/toolkit';
import { ApproveReq, GetCsvDataReq, GetLoginMessageReq, GetReportReq, RequestWithWeb3Provider } from 'types/requests';

import actionTypes from './actionTypes';

export const approve = createAction<ApproveReq>(actionTypes.APPROVE);
export const login = createAction<GetLoginMessageReq>(actionTypes.LOGIN);
export const getReport = createAction<GetReportReq>(actionTypes.GET_REPORT);
export const getCsvData = createAction<GetCsvDataReq>(actionTypes.GET_CSV_DATA);
export const getTokenBalance = createAction<RequestWithWeb3Provider>(actionTypes.GET_CSV_DATA);
