import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import {
  ArrowDown,
  ArrowRight,
  CheckIcon,
  Close,
  Copy,
  Delete,
  Dots,
  Fire,
  FlagFilled,
  FlagOutlined,
  LoaderIcon,
  LogoSmall,
  MainLogo,
  Percent,
  PlusFilledIcon,
  PlusIcon,
  RejectStepIcon,
  SelectArrowDown,
  SelectCheckIcon,
  SuccessStepIcon,
  Unlock,
  WalletIcon,
  WarningIcon,
} from './components';

/* eslint-disable @typescript-eslint/no-shadow */
export enum IconKey {
  MainLogo = 'MainLogo',
  ArrowDown = 'ArrowDown',
  Copy = 'Copy',
  Close = 'Close',
  CheckIcon = 'CheckIcon',
  WalletIcon = 'WalletIcon',
  SelectArrowDown = 'SelectArrowDown',
  SelectCheckIcon = 'SelectCheckIcon',
  LoaderIcon = 'LoaderIcon',
  ArrowRight = 'ArrowRight',
  LogoSmall = 'LogoSmall',
  WarningIcon = 'WarningIcon',
  PlusIcon = 'PlusIcon',
  Fire = 'Fire',
  FlagOutlined = 'FlagOutlined',
  FlagFilled = 'FlagFilled',
  Unlock = 'Unlock',
  Dots = 'Dots',
  Percent = 'Percent',
  PlusFilledIcon = 'PlusFilledIcon',
  Delete = 'Delete',
  RejectStepIcon = 'RejectStepIcon',
  SuccessStepIcon = 'SuccessStepIcon',
}

/* eslint-enable @typescript-eslint/no-shadow */
export const Icon: Record<IconKey, FC<SvgIconProps>> = {
  MainLogo,
  ArrowDown,
  Copy,
  Close,
  CheckIcon,
  WalletIcon,
  SelectArrowDown,
  SelectCheckIcon,
  LoaderIcon,
  ArrowRight,
  LogoSmall,
  WarningIcon,
  PlusIcon,
  Fire,
  FlagOutlined,
  FlagFilled,
  Unlock,
  Dots,
  Percent,
  PlusFilledIcon,
  Delete,
  RejectStepIcon,
  SuccessStepIcon,
};
