import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Percent: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...sx,
      width: '20px',
      height: '20px',
    }}
  >
    <path
      d="M14.5833 17.5C13.8098 17.5 13.0679 17.1927 12.5209 16.6457C11.974 16.0987 11.6667 15.3569 11.6667 14.5833C11.6667 13.8098 11.974 13.0679 12.5209 12.5209C13.0679 11.974 13.8098 11.6667 14.5833 11.6667C15.3569 11.6667 16.0987 11.974 16.6457 12.5209C17.1927 13.0679 17.5 13.8098 17.5 14.5833C17.5 15.3569 17.1927 16.0987 16.6457 16.6457C16.0987 17.1927 15.3569 17.5 14.5833 17.5ZM14.5833 15.8333C14.9149 15.8333 15.2328 15.7016 15.4672 15.4672C15.7016 15.2328 15.8333 14.9149 15.8333 14.5833C15.8333 14.2518 15.7016 13.9339 15.4672 13.6995C15.2328 13.465 14.9149 13.3333 14.5833 13.3333C14.2518 13.3333 13.9339 13.465 13.6995 13.6995C13.465 13.9339 13.3333 14.2518 13.3333 14.5833C13.3333 14.9149 13.465 15.2328 13.6995 15.4672C13.9339 15.7016 14.2518 15.8333 14.5833 15.8333ZM5.41667 8.33333C5.03364 8.33333 4.65437 8.25789 4.30051 8.11132C3.94664 7.96474 3.62511 7.7499 3.35427 7.47906C3.08343 7.20822 2.86859 6.88669 2.72202 6.53283C2.57544 6.17896 2.5 5.79969 2.5 5.41667C2.5 5.03364 2.57544 4.65437 2.72202 4.30051C2.86859 3.94664 3.08343 3.62511 3.35427 3.35427C3.62511 3.08343 3.94664 2.86859 4.30051 2.72202C4.65437 2.57544 5.03364 2.5 5.41667 2.5C6.19021 2.5 6.93208 2.80729 7.47906 3.35427C8.02604 3.90125 8.33333 4.64312 8.33333 5.41667C8.33333 6.19021 8.02604 6.93208 7.47906 7.47906C6.93208 8.02604 6.19021 8.33333 5.41667 8.33333ZM5.41667 6.66667C5.74819 6.66667 6.06613 6.53497 6.30055 6.30055C6.53497 6.06613 6.66667 5.74819 6.66667 5.41667C6.66667 5.08515 6.53497 4.7672 6.30055 4.53278C6.06613 4.29836 5.74819 4.16667 5.41667 4.16667C5.08515 4.16667 4.7672 4.29836 4.53278 4.53278C4.29836 4.7672 4.16667 5.08515 4.16667 5.41667C4.16667 5.74819 4.29836 6.06613 4.53278 6.30055C4.7672 6.53497 5.08515 6.66667 5.41667 6.66667ZM15.8925 2.92917L17.0708 4.1075L4.10833 17.0708L2.93 15.8925L15.8917 2.92917H15.8925Z"
      fill="#B2D795"
    />
  </SvgIcon>
);
