import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ExitIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 15 20"
    sx={{
      width: '15px',
      height: '20px',
      fill: '#323232',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.48733 2.07996H2V17.9201H7.48733C8.03961 17.9201 8.48733 18.3678 8.48733 18.9201C8.48733 19.4724 8.03961 19.9201 7.48733 19.9201H1.81092C1.33063 19.9201 0.870019 19.7293 0.530405 19.3897C0.190792 19.0501 0 18.5895 0 18.1092V1.89087C0 1.41059 0.190793 0.949973 0.530405 0.610361C0.870017 0.270749 1.33063 0.0799561 1.81092 0.0799561H7.48733C8.03961 0.0799561 8.48733 0.527671 8.48733 1.07996C8.48733 1.63224 8.03961 2.07996 7.48733 2.07996ZM9.03557 5.03563C9.42609 4.64511 10.0593 4.64511 10.4498 5.03563L14.7071 9.29294C15.0976 9.68346 15.0976 10.3166 14.7071 10.7072L10.4498 14.9645C10.0593 15.355 9.42609 15.355 9.03557 14.9645C8.64504 14.5739 8.64504 13.9408 9.03557 13.5503L11.5858 11.0001L5.48535 11.0001C4.93307 11.0001 4.48535 10.5523 4.48535 10.0001C4.48535 9.44778 4.93307 9.00006 5.48535 9.00006L11.5858 9.00006L9.03557 6.44984C8.64504 6.05932 8.64504 5.42615 9.03557 5.03563Z"
      fill="currentColor"
    />
  </SvgIcon>
);
