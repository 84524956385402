import { ComponentsOverrides, ComponentsProps } from '@mui/material';

export const getMuiContainerOverrides = (): ComponentsOverrides['MuiContainer'] => ({});

export const getMuiContainerDefaultProps = (): ComponentsProps['MuiContainer'] => ({
  maxWidth: 'lg',
  sx: {
    mx: 'auto',
  },
});
