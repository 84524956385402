const LOGIN = 'LOGIN' as const;
const GET_REPORT = 'GET_REPORT' as const;
const GET_CSV_DATA = 'GET_CSV_DATA' as const;
const APPROVE = 'APPROVE' as const;
const GET_TOKEN_BALANCE = 'GET_TOKEN_BALANCE' as const;

export default {
  LOGIN,
  GET_REPORT,
  GET_CSV_DATA,
  APPROVE,
  GET_TOKEN_BALANCE,
};
