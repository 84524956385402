import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StakingState } from 'types/store/staking';

const initialState: StakingState = {
  pools: [],
  isUserStakingOwner: false,
};

export const stakingReducer = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    updateStakingState: (state: StakingState, action: PayloadAction<Partial<StakingState>>) => ({
      ...state,
      ...action.payload,
    }),

    resetStakingData: () => ({
      ...initialState,
    }),
  },
});

export const { updateStakingState, resetStakingData } = stakingReducer.actions;

export default stakingReducer.reducer;
