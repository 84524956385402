import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { SxProps, TextField, Typography } from '@mui/material';
import { DesktopDateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import {
  COLOR_BEIGE,
  COLOR_BLACK,
  COLOR_GRAY,
  COLOR_GRAY_EXTRALIGHT,
  COLOR_LIGHT_GREEN,
  COLOR_WHITE,
} from 'theme/colors';
import { FontWeights } from 'theme/Typography';
import { BORDER_MAIN, BORDER_MAIN_STRONG, BORDER_RADIUS_DEFAULT, BORDER_RADIUS_SMALL } from 'theme/variables';

export interface DateTimePickerProps {
  value: Moment | null;
  setValue: Dispatch<SetStateAction<Moment | null>>;
  disabled: boolean;
  disablePast?: boolean;
  error?: boolean;
  helperText?: string;
}

const CustomAdornment = () => (
  <>
    <Typography variant="caption" sx={{ mr: 0.5, cursor: 'default' }}>
      UTC
    </Typography>
    <DateRangeOutlinedIcon />
  </>
);
export const DateTimePicker: FC<DateTimePickerProps> = ({
  value,
  setValue,
  disabled,
  disablePast = false,
  error,
  helperText,
}) => {
  const disablePastError = useMemo(() => disablePast && !!value && value < moment(), [disablePast, value]);

  const popperSx: SxProps = {
    '& .MuiPaper-root': {
      backgroundColor: COLOR_BEIGE,
      border: BORDER_MAIN_STRONG,
      borderRadius: BORDER_RADIUS_DEFAULT,
      minHeight: '344px',
      svg: {
        color: COLOR_BLACK,
      },
    },

    '& .MuiPickersArrowSwitcher-root': {
      gap: 0.25,
      '& .MuiPickersArrowSwitcher-button': {
        color: COLOR_BLACK,
        width: 40,
        height: 40,

        '&.Mui-disabled': {
          color: COLOR_GRAY,
        },
      },
    },

    '& .MuiCalendarPicker-root': {
      borderRadius: BORDER_RADIUS_DEFAULT,

      '& .MuiDayPicker-weekDayLabel': {
        color: COLOR_GRAY,
        fontWeight: FontWeights.fontWeightBold,
        fontSize: '11px',
      },

      '& .MuiPickersDay-dayWithMargin': {
        color: COLOR_BLACK,
        backgroundColor: 'transparent',
        fontWeight: FontWeights.fontWeightBold,
        fontSize: '14px',

        '&:focus': {
          backgroundColor: COLOR_GRAY_EXTRALIGHT,
          color: COLOR_BLACK,
        },

        '&:hover': {
          border: `1px solid ${COLOR_BLACK}`,
        },

        '&.MuiPickersDay-today': {
          backgroundColor: COLOR_GRAY_EXTRALIGHT,
          color: COLOR_BLACK,
          border: 'none',

          '&:hover': {
            background: COLOR_GRAY_EXTRALIGHT,
            border: BORDER_MAIN,
          },
        },

        '&.Mui-selected, &.Mui-selected:focus, &.Mui-selected:hover ': {
          backgroundColor: COLOR_BLACK,
          color: COLOR_WHITE,
        },

        '&.Mui-disabled': {
          color: COLOR_GRAY,
        },
      },

      '& .MuiYearPicker-root': {
        '& .PrivatePickersYear-yearButton ': {
          width: 'unset',
          height: '28px',
          fontWeight: FontWeights.fontWeightBold,

          '&.Mui-selected, &.Mui-selected:focus, &.Mui-selected:hover ': {
            backgroundColor: COLOR_GRAY,
            color: COLOR_WHITE,
          },

          '&:hover': {
            border: `1px solid ${COLOR_BLACK}`,
          },

          '&.Mui-disabled': {
            color: COLOR_GRAY,

            '&:hover': {
              border: 'none',
            },
          },
        },
      },
    },

    '& .MuiClock-root': {
      paddingTop: '50px',

      '& .MuiClock-clock': {
        backgroundColor: 'transparent',
        border: BORDER_MAIN,

        '& .MuiClock-pin, .MuiClockPointer-root': {
          backgroundColor: COLOR_LIGHT_GREEN,
          '& .MuiClockPointer-thumb': {
            border: 'none',
            background: 'transparent',
          },
        },

        '& .MuiClockNumber-root': {
          color: COLOR_BLACK,
          '&.Mui-selected': {
            backgroundColor: COLOR_LIGHT_GREEN,
            color: COLOR_BLACK,
            border: BORDER_MAIN,
          },
        },
      },

      '& .MuiClock': {
        '&-amButton, &-pmButton': {
          borderRadius: BORDER_RADIUS_SMALL,
          height: '36px',
          width: '36px',
          fontWeight: FontWeights.fontWeightRegular,
          span: { color: COLOR_BLACK },
          backgroundColor: 'transparent',
          border: BORDER_MAIN,

          '& .Mui-selected': {
            backgroundColor: COLOR_LIGHT_GREEN,
          },
          '&.Mui-selected, &.Mui-selected:focus, &.Mui-selected:hover ': {
            backgroundColor: COLOR_LIGHT_GREEN,
          },

          '&:hover': {
            backgroundColor: COLOR_LIGHT_GREEN,
          },
        },
        '&-pmButton span': {
          color: COLOR_BLACK,
        },
      },
    },
  };

  return (
    <DesktopDateTimePicker
      value={value?.utc() || null}
      onChange={(newValue: Moment | null) => {
        setValue(newValue?.utc() || null);
      }}
      timezone="UTC"
      disablePast={disablePast}
      slots={{
        textField: TextField,
        openPickerIcon: CustomAdornment,
      }}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      }}
      slotProps={{
        textField: {
          disabled,
          color: 'secondary',
          placeholder: 'dd.mm.yyyy hh:mm',
          size: 'small',
          error: disablePastError || error,
          helperText: disablePastError ? 'Past dates not available' : helperText,
        },
        popper: {
          sx: popperSx,
        },
        openPickerButton: { style: { color: COLOR_LIGHT_GREEN } },
      }}
    />
  );
};
