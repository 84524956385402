export default {
  // login
  getMessageToSign: '/auth/message_to_sign',
  login: '/auth/login',
  // report
  getRewardReport: '/report/reward',
  getDadRewardReport: '/report/reward-user-dad',
  getSonsRewardReport: '/report/reward-user-sons',
  getRewardReportCsv: '/report/reward-csv',
  getDadRewardReportCsv: '/report/reward-user-dad-csv',
  getSonsRewardReportCsv: '/report/reward-user-sons-csv',
};
