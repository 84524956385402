import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FlagFilled: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 21 21"
    sx={{
      ...sx,
      width: '21px',
      height: '21px',
    }}
  >
    <path
      d="M3.96429 3.75H11.0008C11.14 3.75007 11.2765 3.78891 11.3949 3.86216C11.5134 3.93542 11.6091 4.04019 11.6713 4.16475L12.2143 5.25H16.7143C16.9132 5.25 17.104 5.32902 17.2446 5.46967C17.3853 5.61032 17.4643 5.80109 17.4643 6V14.25C17.4643 14.4489 17.3853 14.6397 17.2446 14.7803C17.104 14.921 16.9132 15 16.7143 15H11.9278C11.7885 14.9999 11.6521 14.9611 11.5336 14.8878C11.4152 14.8146 11.3195 14.7098 11.2573 14.5853L10.7143 13.5H5.46429V18H3.96429V3.75Z"
      fill="#0A0D14"
    />
  </SvgIcon>
);
