import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Unlock: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 21 21"
    sx={{
      width: '20px',
      height: '20px',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M6.39288 8.99995H16.1429C16.3418 8.99995 16.5326 9.07896 16.6732 9.21962C16.8139 9.36027 16.8929 9.55103 16.8929 9.74995V17.2499C16.8929 17.4489 16.8139 17.6396 16.6732 17.7803C16.5326 17.9209 16.3418 17.9999 16.1429 17.9999H4.14288C3.94397 17.9999 3.75321 17.9209 3.61255 17.7803C3.4719 17.6396 3.39288 17.4489 3.39288 17.2499V9.74995C3.39288 9.55103 3.4719 9.36027 3.61255 9.21962C3.75321 9.07896 3.94397 8.99995 4.14288 8.99995H4.89288V8.24995C4.89246 7.06697 5.29157 5.91853 6.02553 4.99077C6.75948 4.06302 7.78526 3.41033 8.93658 3.13852C10.0879 2.8667 11.2973 2.99169 12.3687 3.49323C13.4401 3.99476 14.3107 4.84344 14.8394 5.9017L13.4976 6.5722C13.12 5.81621 12.4981 5.20993 11.7328 4.85166C10.9674 4.49338 10.1035 4.40411 9.28104 4.59833C8.45861 4.79254 7.72588 5.25885 7.20165 5.92165C6.67742 6.58445 6.39243 7.40489 6.39288 8.24995V8.99995ZM4.89288 10.4999V16.4999H15.3929V10.4999H4.89288ZM8.64288 12.7499H11.6429V14.2499H8.64288V12.7499Z"
      fill="#0A0D14"
    />
  </SvgIcon>
);
