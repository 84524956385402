/* eslint-disable camelcase */
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { all, call, put, takeLatest } from 'typed-redux-saga';
import { RewardState } from 'types';
import { camelize } from 'utils';

import { getReport } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

const formatData = (data: any): RewardState => {
  const camelizedResponse: any = camelize(data);
  return {
    ...camelizedResponse,
    items: camelizedResponse.items.map((el: any) => ({
      refCode: el.user.promoCode,
      address: el.user.address,
      rewardAmount: `${el.amountUsd}${el?.token?.symbol ? ` ${el?.token?.symbol}` : ''}`,
      status: el.user.status,
      statusExpiresAt: el.user.statusExpiresAt,
    })),
  };
};

export function* getReportSaga({
  type,
  payload: { reportType, dateFrom, dateTo, userAddress, page },
}: ReturnType<typeof getReport>) {
  yield put(apiActions.request(type));
  const isFirstReportType = reportType === '1';
  try {
    if (isFirstReportType) {
      const { data } = yield* call(baseApi.getRewardReport, {
        page,
        per_page: '7',
        start: dateFrom,
        end: dateTo,
      });
      const rewardReport = formatData(data);
      yield put(
        updateUserState({
          rewardReport,
        }),
      );
    } else if (userAddress) {
      const [{ data: userDadReport }, { data: userSonsReport }] = yield all([
        call(baseApi.getUserDadReport, {
          page,
          address: userAddress,
          per_page: '7',
          start: dateFrom,
          end: dateTo,
        }),
        call(baseApi.getUserSonsReport, {
          page,
          address: userAddress,
          per_page: '7',
          start: dateFrom,
          end: dateTo,
        }),
      ]);
      const userDadRewardReport = formatData(userDadReport);
      const userSonsRewardReport = formatData(userSonsReport);
      yield put(
        updateUserState({
          userDadRewardReport,
          userSonsRewardReport,
        }),
      );
    }

    yield put(apiActions.success(type));
  } catch (err) {
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_REPORT, getReportSaga);
}
