import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Button, SxProps, Typography } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';
import { Copy as CopyIcon } from 'components/Icon/components';
import { COLOR_WHITE } from 'theme/colors';
import { BORDER_MAIN, BORDER_RADIUS_DEFAULT } from 'theme/variables';

export interface CopyProps {
  copyText: string;
  children?: ReactNode;
  sx?: SxProps;
}

export const COPY_TEXT_SHOW_TIME = 2000;

export const Copy: FC<CopyProps> = ({ copyText, children, sx }) => {
  const { t } = useTranslation();
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setHelperText('');
    }, COPY_TEXT_SHOW_TIME);
    return () => clearTimeout(timer);
  }, [helperText]);

  const onCopy = useCallback(() => {
    setHelperText(t('header.copied'));
  }, [t]);

  return (
    <CopyToClipboard onCopy={onCopy} text={copyText}>
      <Button
        sx={(theme) => {
          const styles = {
            theme: theme.spacing(2, 2, 2, 3),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: '60px',
            borderRadius: BORDER_RADIUS_DEFAULT,
            background: COLOR_WHITE,
            border: BORDER_MAIN,
            cursor: 'pointer',

            '&:hover': {
              background: COLOR_WHITE,
            },

            ...(sx as CSSProperties),
          };
          return { ...styles };
        }}
      >
        {children}
        {helperText && (
          <Typography
            className="s"
            sx={{
              position: 'absolute',
              bottom: -2,
              left: '50%',
              transform: 'translateX(-50%)',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'none',
            }}
          >
            {helperText}
          </Typography>
        )}
        <CopyIcon />
      </Button>
    </CopyToClipboard>
  );
};
