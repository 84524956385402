/* PLOP_INJECT_IMPORT_REDUCER */
import modals from './modal/reducer';
import staking from './staking/reducer';
import ui from './ui/reducer';
import user from './user/reducer';

export default {
  ui,
  user,
  modals,
  staking,
};
