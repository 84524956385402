import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { COLOR_BLACK, COLOR_GRAY, COLOR_GRAY_LIGHT, COLOR_GRAY_MEDIUM, COLOR_WHITE } from 'theme/colors';
import { FontFamilies, FontWeights } from 'theme/Typography';
import { BORDER_MAIN_STRONG, BORDER_RADIUS_DEFAULT } from 'theme/variables';

export const getMuiTextFieldOverrides = (theme?: Theme): ComponentsOverrides['MuiTextField'] => ({
  root: {
    boxShadow: 'none',
    border: '1px solid',
    borderColor: 'transparent',
    borderRadius: BORDER_RADIUS_DEFAULT,
    fontSize: '16px',
    '& input::placeholder': {
      opacity: 1,
      color: COLOR_GRAY_LIGHT,
      fontWeight: FontWeights.fontWeightRegular,
    },
    '& .MuiInputBase-input': {
      fontSize: '23px',
    },
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
    },

    '.MuiInputBase-root': {
      '&.MuiInputBase-sizeSmall': {
        height: '48px !important',
        '.MuiInputBase-input': { padding: theme?.spacing(1, 2) },
        '& .MuiInputBase-input': {
          fontSize: '14px',
          lineHeight: '32px',
          fontWeight: FontWeights.fontWeightBold,
          fontFamily: FontFamilies.secondary,
        },
      },
    },

    '& .MuiOutlinedInput-root': {
      overflow: 'hidden',
      color: COLOR_BLACK,
      borderRadius: BORDER_RADIUS_DEFAULT,

      '& .Mui-disabled': {
        background: COLOR_GRAY_MEDIUM,
      },

      '& fieldset': {
        border: '1px solid',
        borderColor: COLOR_BLACK,

        '&.MuiOutlinedInput-notchedOutline': {
          borderColor: COLOR_BLACK,
        },
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${COLOR_BLACK}`,
      },

      '&.MuiInputBase-colorSecondary': {
        background: COLOR_WHITE,
        '& fieldset': {
          border: BORDER_MAIN_STRONG,
        },

        '&.Mui-disabled': {
          background: COLOR_GRAY_MEDIUM,
        },
        '& .Mui-disabled': {
          background: COLOR_GRAY_MEDIUM,
          color: COLOR_BLACK,
          WebkitTextFillColor: COLOR_GRAY,
          '&:before': {
            border: 'none !important',
          },
        },
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'rgba(26, 26, 26, 0.2)',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'rgba(26, 26, 26, 0.2)',
    },
    '& .MuiFormHelperText-root': {
      fontSize: '16px',
      marginLeft: 0,
    },
  },
});

export const getMuiTextFieldDefaultProps = (): ComponentsProps['MuiTextField'] => ({
  InputProps: {
    style: {
      height: '60px',
      padding: '0 14px',
      background: COLOR_WHITE,
    },
  },
});
