import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isProduction } from 'appConstants';
import { Chains, UserState } from 'types';

const initialState: UserState = {
  address: '',
  provider: '',
  chainType: isProduction ? 'mainnet' : 'testnet', // TODO: change on mainnet preferably
  network: Chains.bsc,
  key: '',
  tokenBalance: '',
  tokenDecimals: 0,
  rewardReport: {
    items: [],
    totalPages: null,
    totalItems: null,
  },
  userDadRewardReport: {
    items: [],
    totalPages: null,
    totalItems: null,
  },
  userSonsRewardReport: {
    items: [],
    totalPages: null,
    totalItems: null,
  },
  rewardCsv: '',
  userDadRewardCsv: '',
  userSonsRewardCsv: '',
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserState: (state: UserState, action: PayloadAction<Partial<UserState>>) => ({
      ...state,
      ...action.payload,
    }),
    disconnectWalletState: () => {
      localStorage.removeItem('walletconnect');
      return {
        ...initialState,
      };
    },
  },
});

export const { disconnectWalletState, updateUserState } = userReducer.actions;

export default userReducer.reducer;
