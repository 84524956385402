import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PlusFilledIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 25"
    sx={{
      width: '24px',
      height: '25px',
      ...sx,
    }}
  >
    <path
      d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM11 11.5H7V13.5H11V17.5H13V13.5H17V11.5H13V7.5H11V11.5Z"
      fill="#323232"
    />
  </SvgIcon>
);
