import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Stack } from '@mui/material';
import { routes } from 'appConstants';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';

import { NotificationModal } from '../NotificationModal';
import { Header } from '..';

export interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAuthenticated = useShallowSelector(userSelector.selectIsAuthenticated);

  const isLoginPage = useMemo(() => pathname === '/', [pathname]);

  useEffect(() => {
    if (isLoginPage && isAuthenticated) {
      navigate(routes.select.root.path);
    }
  }, [isAuthenticated, isLoginPage, navigate]);

  useEffect(() => {
    if (!isLoginPage && !isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, isLoginPage, navigate]);
  return (
    <Box
      sx={{
        minHeight: '100vh',
      }}
    >
      {!isLoginPage && <Header />}
      <NotificationModal />

      <Stack width="100%" direction="column">
        <Container
          sx={{
            height: '100%',
            pb: 3,
          }}
          component="main"
        >
          {children}
        </Container>
      </Stack>
    </Box>
  );
};
