import { Chains, IChainType } from './connect';

export * from './connect';
export * from './store';
export * from './routes';
export * from './components';

export type TOptionable<T> = T | undefined;

export enum WalletProviders {
  metamask = 'MetaMask',
  walletConnect = 'WalletConnect',
}
export interface INotifyModalProps {
  currData?: {
    [k: string]: string;
  };
  closeModal: () => void;
}

export enum Modules {
  login = 'login',
  main = 'main',
}

export enum Tokens {
  USDT = 'USDT',
  AGTI = 'AGTI',
}

export type Nullable<T> = null | T;
export type Optional<T> = undefined | T;

export type NativeCurrency = {
  name: string;
  symbol: string; // 2-6 characters long
  decimals: number;
};
// @see https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain

interface AddEthereumChainParameter {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: NativeCurrency;
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
}

export type TChain = {
  name: string;
  chainId: number;
  nativeCurrency: NativeCurrency;
  rpc: string;
  blockExplorerUrl: string;
  provider: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  img?: string;
  network?: AddEthereumChainParameter;
};

export type TChains = Record<
  Chains,
  {
    [chainType in IChainType]: TChain;
  }
>;
