import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FlagOutlined: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...sx,
      width: '20px',
      height: '20px',
    }}
  >
    <path
      d="M5.17859 13.5V18H3.67859V3.75H10.7151C10.8543 3.75007 10.9908 3.78891 11.1092 3.86216C11.2277 3.93542 11.3233 4.04019 11.3856 4.16475L11.9286 5.25H16.4286C16.6275 5.25 16.8183 5.32902 16.9589 5.46967C17.0996 5.61032 17.1786 5.80109 17.1786 6V14.25C17.1786 14.4489 17.0996 14.6397 16.9589 14.7803C16.8183 14.921 16.6275 15 16.4286 15H11.6421C11.5028 14.9999 11.3664 14.9611 11.2479 14.8878C11.1295 14.8146 11.0338 14.7098 10.9716 14.5853L10.4286 13.5H5.17859ZM5.17859 5.25V12H11.3556L12.1056 13.5H15.6786V6.75H11.0016L10.2516 5.25H5.17859Z"
      fill="#0A0D14"
    />
  </SvgIcon>
);
