import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Routes } from 'react-router-dom';
import { routes as appRoutes } from 'appConstants/routes';
import { useShallowSelector } from 'hooks';
import { getLazyRoutes } from 'modules/router/utils';
import stakingSelector from 'store/staking/selectors';
import userSelector from 'store/user/selectors';

export const RouteManager = () => {
  const isAuthenticated = useShallowSelector(userSelector.selectIsAuthenticated);
  const { isUserStakingOwner } = useShallowSelector(stakingSelector.getStakingState);
  const lazyRotes = useMemo(
    () => getLazyRoutes(appRoutes, isAuthenticated, isUserStakingOwner),
    [isAuthenticated, isUserStakingOwner],
  );
  const [routes, setRoutes] = useState<ReactElement[] | null>();
  useEffect(() => {
    setRoutes(lazyRotes);
  }, [lazyRotes]);

  return routes ? <Routes>{routes}</Routes> : null;
};
