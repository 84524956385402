import { FC } from 'react';
import { Box, Container, Stack } from '@mui/material';
import { MainLogo } from 'components/Icon/components';
import { useModal, useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services';
import userSelector from 'store/user/selectors';
import { BORDER_MAIN, TRANSITION_DEFAULT_TIME } from 'theme/variables';
import { State, UserState } from 'types';

import { Account } from '../Account';

import { DisconnectModal } from './components/DisconnectModal';

export const Header: FC = () => {
  const { disconnect } = useWalletConnectorContext();
  const { address } = useShallowSelector<State, UserState>(userSelector.getUser);
  const [isDisconnectModalOpen, handleOpenDisconnectModal, handleCloseDisconnectModal] = useModal(false);

  return (
    <Box
      sx={{
        pt: 3,
        position: 'relative',
        top: '0',
        zIndex: '2',
        background: 'transparent',
        transition: TRANSITION_DEFAULT_TIME,
      }}
    >
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            pb: 3,
            borderBottom: BORDER_MAIN,
          }}
        >
          <MainLogo sx={{ width: 64, height: 64 }} />
          <Account address={address} onClick={handleOpenDisconnectModal} />
        </Stack>
      </Container>
      <DisconnectModal open={isDisconnectModalOpen} onClose={handleCloseDisconnectModal} disconnect={disconnect} />
    </Box>
  );
};
