import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { UserState } from 'types';
import { StakingAbi } from 'types/contracts/StakingAbi';
import { AbiItem } from 'web3-utils';

import { getStakingOwner } from '../actions';
import actionTypes from '../actionTypes';
import { updateStakingState } from '../reducer';

export function* getStakingOwnerSaga({ type, payload: { web3Provider } }: ReturnType<typeof getStakingOwner>) {
  yield* put(apiActions.request(type));
  const { address: userAddress, network, chainType }: UserState = yield select(userSelector.getUser);

  const { address: stakingContractAddress, abi: stakingAbi } =
    contractsConfig.contracts[ContractsNames.staking][chainType];

  const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(
    stakingAbi as AbiItem[],
    stakingContractAddress[network],
  );

  try {
    const owner = yield* call(stakingContract.methods.owner().call);

    yield put(
      updateStakingState({
        isUserStakingOwner: userAddress.toLowerCase() === owner.toLowerCase(),
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_STAKING_OWNER, getStakingOwnerSaga);
}
