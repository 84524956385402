/* eslint-disable camelcase */
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { all, call, put, takeLatest } from 'typed-redux-saga';

import { getCsvData } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getCsvDataSaga({
  type,
  payload: { reportType, dateFrom, dateTo, userAddress },
}: ReturnType<typeof getCsvData>) {
  yield put(apiActions.request(type));
  const isFirstReportType = reportType === '1';
  try {
    if (isFirstReportType) {
      const { data: rewardCsv } = yield* call(baseApi.getRewardReportCSV, {
        start: dateFrom,
        end: dateTo,
      });
      yield put(
        updateUserState({
          rewardCsv,
        }),
      );
    } else if (userAddress) {
      const [{ data: userDadRewardCsv }, { data: userSonsRewardCsv }] = yield all([
        call(baseApi.getUserDadRewardCSV, {
          address: userAddress,
          start: dateFrom,
          end: dateTo,
        }),
        call(baseApi.getUserSonsRewardCSV, {
          address: userAddress,
          start: dateFrom,
          end: dateTo,
        }),
      ]);
      yield put(
        updateUserState({
          userDadRewardCsv,
          userSonsRewardCsv,
        }),
      );
    }
    yield put(apiActions.success(type));
  } catch (err) {
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_CSV_DATA, getCsvDataSaga);
}
