import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import store from 'store/configureStore';
import { setActiveModal } from 'store/modals/reducer';
import stakingSelector from 'store/staking/selectors';
import userActionTypes from 'store/user/actionTypes';
import { getTokenBalanceSaga } from 'store/user/sagas/getTokenBalance';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals, StakingState, UserState } from 'types';
import { StakingAbi } from 'types/contracts/StakingAbi';
import { estimateGasForMethod } from 'utils';
import { AbiItem } from 'web3-utils';

import { deletePool } from '../actions';
import actionTypes from '../actionTypes';
import { updateStakingState } from '../reducer';

export function* deletePoolSaga({
  type,
  payload: { web3Provider, poolId },
}: ReturnType<typeof deletePool>): Generator<unknown, void, any> {
  yield* put(apiActions.request(type));
  const { address: userAddress, network, chainType }: UserState = yield select(userSelector.getUser);
  const { pools }: StakingState = yield select(stakingSelector.getStakingState);

  const { address: stakingContractAddress, abi: stakingAbi } =
    contractsConfig.contracts[ContractsNames.staking][chainType];

  const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(
    stakingAbi as AbiItem[],
    stakingContractAddress[network],
  );

  try {
    yield* put(
      setActiveModal({
        activeModal: Modals.SendPendingSimple,
        open: true,
      }),
    );

    const { estimatedGas, gasPrice } = yield* call(estimateGasForMethod, {
      web3Provider,
      contract: stakingContract,
      method: 'cancelPool',
      estimateGasArgs: { from: userAddress },
      methodArgs: [poolId],
    });

    const { transactionHash } = yield call(stakingContract.methods.cancelPool(poolId).send, {
      from: userAddress,
      gasPrice: Number(gasPrice),
      gas: estimatedGas,
    });

    const newPools = pools.filter((pool) => pool.id !== poolId);

    yield put(
      updateStakingState({
        pools: newPools,
      }),
    );

    yield call(getTokenBalanceSaga, {
      type: userActionTypes.GET_TOKEN_BALANCE,
      payload: { web3Provider },
    });

    yield* put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: transactionHash,
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    console.error(err);
    yield* put(apiActions.error(type, err));
    yield* put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
        repeatHandler: () =>
          store.store.dispatch({
            type,
            payload: {
              web3Provider,
              poolId,
            },
          }),
      }),
    );
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.DELETE_POOL, deletePoolSaga);
}
