import { alpha } from '@mui/material';

export const COLOR_WHITE = '#FFFFFF';

export const COLOR_BEIGE = '#F4F0E7';
export const COLOR_LIGHT_BEIGE = '#E8E5E0';

export const COLOR_RED = '#FF4B23';
export const COLOR_RED_SECONDARY = '#F54923';

export const COLOR_BLUE = '#5697F5';

export const COLOR_GRAY = '#707070';
export const COLOR_GRAY_MEDIUM = '#E9E7E2';
export const COLOR_GRAY_SEMILIGHT = '#8B8B8B';
export const COLOR_GRAY_LIGHT = '#B3B3B3';
export const COLOR_GRAY_EXTRALIGHT = '#d3d3d3';

export const COLOR_GREEN = '#3D7C0B';
export const COLOR_LIGHT_GREEN = '#B2D795';

export const COLOR_BLACK = '#323232';
export const COLOR_BLACK_ALPHA = alpha('#323232', 0.53);
