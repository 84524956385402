import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Delete: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 25"
    sx={{
      width: '24px',
      height: '25px',
      color: '#323232',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M17 6.5H22V8.5H20V21.5C20 21.7652 19.8946 22.0196 19.7071 22.2071C19.5196 22.3946 19.2652 22.5 19 22.5H5C4.73478 22.5 4.48043 22.3946 4.29289 22.2071C4.10536 22.0196 4 21.7652 4 21.5V8.5H2V6.5H7V3.5C7 3.23478 7.10536 2.98043 7.29289 2.79289C7.48043 2.60536 7.73478 2.5 8 2.5H16C16.2652 2.5 16.5196 2.60536 16.7071 2.79289C16.8946 2.98043 17 3.23478 17 3.5V6.5ZM18 8.5H6V20.5H18V8.5ZM9 11.5H11V17.5H9V11.5ZM13 11.5H15V17.5H13V11.5ZM9 4.5V6.5H15V4.5H9Z"
      fill="currentColor"
    />
  </SvgIcon>
);
