import { buttonClasses, ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { COLOR_BLACK, COLOR_GREEN, COLOR_LIGHT_GREEN, COLOR_WHITE } from 'theme/colors';
import { FontFamilies, FontWeights } from 'theme/Typography';
import { BORDER_GREEN, BORDER_GREEN_BOLD, BORDER_MAIN, BORDER_MAIN_STRONG, BORDER_RADIUS_SMALL } from 'theme/variables';

export const getMuiButtonOverrides = (theme: Theme): ComponentsOverrides['MuiButton'] => ({
  root: {
    textAlign: 'center',
    borderRadius: BORDER_RADIUS_SMALL,
    fontWeight: FontWeights.fontWeightRegular,
    padding: theme.spacing(1, 2),
    minWidth: '75px',
    fontSize: '20px',
    lineHeight: '24px',
    fontFamily: '"Helvetica Neue", sans-serif',
    boxShadow: 'none',
    textTransform: 'none',

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },

    '&:active': {
      boxShadow: 'none',
    },

    [`&.${buttonClasses.disabled}`]: {
      opacity: 0.4,
      color: COLOR_BLACK,
    },
  },

  startIcon: {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
    margin: 0,
  },

  endIcon: {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
    marginLeft: theme.spacing(1.25),
  },

  sizeSmall: {
    minWidth: '78px',
    fontSize: '19px',
    lineHeight: '22px',
    padding: theme.spacing(1, 2),
    height: '40px',
  },

  sizeMedium: {
    padding: theme.spacing(1.6, 2.5),
    height: '44px',
  },

  sizeLarge: {
    height: '48px',
    borderWidth: '2px',
    fontSize: 16,
    lineHeight: 20,
    fontFamily: FontFamilies.secondary,
    fontWeight: FontWeights.fontWeightSemiBold,
    '&:hover': { borderWidth: '2px' },
  },

  outlined: {
    border: BORDER_MAIN,

    '&:hover': {
      color: COLOR_GREEN,
      border: BORDER_GREEN,
    },

    '&:active': {
      color: COLOR_BLACK,
      border: BORDER_MAIN,
      backgroundColor: COLOR_LIGHT_GREEN,
    },
  },

  outlinedSecondary: {
    color: COLOR_BLACK,
    backgroundColor: 'transparent',
    border: BORDER_MAIN,
  },

  contained: {
    backgroundColor: COLOR_LIGHT_GREEN,
    border: BORDER_MAIN,
    color: COLOR_BLACK,

    '&:hover': {
      color: COLOR_GREEN,
      border: BORDER_GREEN,
    },

    '&:active': {
      color: COLOR_BLACK,
      border: BORDER_MAIN,
      backgroundColor: COLOR_WHITE,
    },
  },

  containedSecondary: {
    backgroundColor: COLOR_LIGHT_GREEN,
    border: BORDER_MAIN_STRONG,
    color: COLOR_BLACK,
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: FontWeights.fontWeightSemiBold,
    fontFamily: FontFamilies.secondary,

    '&:hover': {
      color: COLOR_GREEN,
      border: BORDER_GREEN_BOLD,
    },

    '&:active': {
      color: COLOR_BLACK,
      border: BORDER_MAIN_STRONG,
      backgroundColor: COLOR_WHITE,
    },
  },

  text: {
    background: 'none',
    backgroundColor: 'transparent',
    transition: '0',
    boxSizing: 'border-box',
    borderColor: 'transparent',
    color: COLOR_BLACK,
    borderRadius: 0,
    padding: 0,
    margin: 0,
    minWidth: 'inherit',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  textSecondary: {
    padding: '0 !important',
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: FontWeights.fontWeightBold,
    fontFamily: FontFamilies.secondary,
    height: 'fit-content !important',
  },
});

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  variant: 'contained',
});
