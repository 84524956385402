import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import store from 'store/configureStore';
import { setActiveModal } from 'store/modal/reducer';
import stakingSelector from 'store/staking/selectors';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals, UserState } from 'types';
import { StakingAbi } from 'types/contracts/StakingAbi';
import { StakingState } from 'types/store/staking';
import { estimateGasForMethod } from 'utils';
import { AbiItem } from 'web3-utils';

import { createPool } from '../actions';
import actionTypes from '../actionTypes';
import { updateStakingState } from '../reducer';

export function* createPoolSaga({ type, payload: { web3Provider, ...poolData } }: ReturnType<typeof createPool>) {
  yield* put(apiActions.request(type));
  const { acceptStakesStart, acceptStakesEnd, stakingEnd, targetAprInfo, claimAvailable } = poolData;

  const { address: userAddress, network, chainType }: UserState = yield select(userSelector.getUser);
  const { pools }: StakingState = yield select(stakingSelector.getStakingState);

  const { address: stakingContractAddress, abi: stakingAbi } =
    contractsConfig.contracts[ContractsNames.staking][chainType];

  const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(
    stakingAbi as AbiItem[],
    stakingContractAddress[network],
  );

  yield* put(
    setActiveModal({
      activeModal: Modals.SendPendingSimple,
      open: true,
    }),
  );

  const { estimatedGas, gasPrice } = yield* call(estimateGasForMethod, {
    web3Provider,
    contract: stakingContract,
    method: 'createPool',
    estimateGasArgs: { from: userAddress },
    methodArgs: [acceptStakesStart, acceptStakesEnd, stakingEnd, targetAprInfo, claimAvailable],
  });

  try {
    const { transactionHash } = yield* call(
      stakingContract.methods.createPool(
        +acceptStakesStart,
        +acceptStakesEnd,
        +stakingEnd,
        targetAprInfo,
        claimAvailable,
      ).send,
      {
        from: userAddress,
        gasPrice: Number(gasPrice),
        gas: estimatedGas,
      },
    );

    const newPool = {
      id: pools.length,
      acceptStakesStart,
      claimStart: acceptStakesEnd,
      unstakeStart: stakingEnd,
      apr: +targetAprInfo,
      totalStaked: '0',
      totalStakedCurrent: '0',
      totalRewardAmount: '0',
      currentRewardAmount: '0',
      claimAvailable,
      cancelled: false,
    };

    yield* put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: transactionHash,
      }),
    );

    yield put(
      updateStakingState({
        pools: [...pools, newPool],
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    yield* put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
        repeatHandler: () =>
          store.store.dispatch({
            type,
            payload: {
              web3Provider,
              acceptStakesStart,
              acceptStakesEnd,
              stakingEnd,
              targetAprInfo,
              claimAvailable,
            },
          }),
      }),
    );
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CREATE_POOL, createPoolSaga);
}
