import { fork } from 'redux-saga/effects';

import createPool from './createPool';
import deletePool from './deletePool';
import depositReward from './depositReward';
import getPools from './getPools';
import getStakingOwner from './getStakingOwner';

export default function* stakingSagas() {
  yield fork(getPools);
  yield fork(createPool);
  yield fork(depositReward);
  yield fork(deletePool);
  yield fork(getStakingOwner);
}
