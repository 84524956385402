import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IConnect, IError } from '@amfi/connect-wallet/dist/interface';
import { useShallowSelector } from 'hooks';
import { Subscription } from 'rxjs';
import { notifyText } from 'services/WalletService/config/constants';
import { login } from 'store/user/actions';
import { disconnectWalletState } from 'store/user/reducer';
import userSelector from 'store/user/selectors';
import { Chains, State, UserState, WalletProviders } from 'types';
import { getToastMessage } from 'utils';

import { WalletService } from '../WalletService';

const closeWalletConnectModalErrorText = "Cannot read properties of undefined (reading 'connector')";

interface IContextValue {
  connect: (provider: WalletProviders, chain: Chains) => Promise<void>;
  disconnect: () => void;
  walletService: WalletService;
}

interface WalletConnectProps {
  children: ReactNode;
}

type IAccountInfo = IConnect | IError | { address: string };

const Web3Context = createContext({} as IContextValue);

const WalletConnectContext: FC<WalletConnectProps> = ({ children }) => {
  const [currentSubscriber, setCurrentSubscriber] = useState<Subscription>();
  const WalletConnect = useMemo(() => new WalletService(), []);
  const dispatch = useDispatch();
  const {
    address,
    key: token,
    provider: WalletProvider,
    chainType,
    network,
  } = useShallowSelector<State, UserState>(userSelector.getUser);

  const disconnect = useCallback(() => {
    dispatch(disconnectWalletState());
    WalletConnect.resetConnect();
    currentSubscriber?.unsubscribe();
    getToastMessage('info', notifyText.disconnet.info);
  }, [WalletConnect, currentSubscriber, dispatch]);

  const subscriberSuccess = useCallback(
    (res: { name: string }) => {
      if (document.visibilityState !== 'visible') {
        disconnect();
      }
      if (res.name === 'accountsChanged') {
        disconnect();
      }
    },
    [disconnect],
  );

  const subscriberError = useCallback(() => {
    WalletConnect.resetConnect();
    getToastMessage('error', 'You changed to wrong network. Please choose Binance-Smart-Chain');
    dispatch(disconnectWalletState());
  }, [WalletConnect, dispatch]);

  const connect = useCallback(
    async (provider: WalletProviders, chain: Chains) => {
      try {
        const connected = await WalletConnect.initWalletConnect(provider, chain, chainType);
        if (connected) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const accountInfo: IAccountInfo = await WalletConnect.getAccount();
          const accountAddress = (accountInfo as IConnect).address;
          const currentProvider = (accountInfo as IError).type;
          if (accountAddress && currentProvider) {
            dispatch(
              login({ address: accountAddress, web3Provider: WalletConnect.Web3(), token, provider: currentProvider }),
            );
          }
          if (!currentSubscriber) {
            const sub = WalletConnect.eventSubscribe().subscribe(subscriberSuccess, subscriberError);
            setCurrentSubscriber(sub);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.message === closeWalletConnectModalErrorText) {
          return;
        }
        getToastMessage('error', `Failed to connect wallet`);
        if (error.code === 4) {
          window.open(
            `https://metamask.app.link/dapp/${window.location.hostname + window.location.pathname}/?utm_source=mm`,
          );
        }
      }
    },
    [WalletConnect, chainType, currentSubscriber, dispatch, subscriberError, subscriberSuccess, token],
  );

  useEffect(() => {
    // connect user if he connected previously
    if (WalletProvider && !address.length) {
      connect(WalletProviders.metamask, network);
    }
  }, [WalletProvider, address.length, connect, network]);

  return (
    <Web3Context.Provider value={{ connect, disconnect, walletService: WalletConnect }}>
      {children}
    </Web3Context.Provider>
  );
};

const useWalletConnectorContext = () => useContext(Web3Context);

export { WalletConnectContext, useWalletConnectorContext };
