import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { COLOR_BLACK, COLOR_GREEN, COLOR_LIGHT_GREEN, COLOR_WHITE } from 'theme/colors';
import { BORDER_MAIN_STRONG } from 'theme/variables';

export const getMuiIconButtonOverrides = (): ComponentsOverrides['MuiIconButton'] => ({
  root: {
    padding: 0,
  },
  sizeSmall: {
    color: COLOR_BLACK,
    '&:hover': {
      color: COLOR_GREEN,
    },
  },
  sizeMedium: {
    width: 56,
    height: 56,
    borderRadius: '50%',
    border: `1px solid ${COLOR_BLACK}`,
    color: COLOR_BLACK,
    padding: 0,
    '&:hover': {
      background: COLOR_LIGHT_GREEN,
      color: COLOR_WHITE,
    },
  },
  sizeLarge: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    border: BORDER_MAIN_STRONG,
    color: COLOR_BLACK,
    padding: 0,
    '&:hover': {
      background: COLOR_LIGHT_GREEN,
      color: COLOR_WHITE,
    },
  },
});

export const getMuiIconButtonDefaultProps = (): ComponentsProps['MuiIconButton'] => ({
  disableFocusRipple: true,
  disableRipple: true,
  size: 'small',
});
