import stakingActionTypes from 'store/staking/actionTypes';
import userActionTypes from 'store/user/actionTypes';
import { UIState } from 'types';
import { RequestStatus } from 'types/store';

import { getUIReducer } from '.';

const initialState: UIState = {
  [userActionTypes.LOGIN]: RequestStatus.INIT,
  [stakingActionTypes.CREATE_POOL]: RequestStatus.INIT,
  [stakingActionTypes.DEPOSIT_REWARD]: RequestStatus.INIT,
  [stakingActionTypes.DELETE_POOL]: RequestStatus.INIT,
};

const uiReducer = getUIReducer(initialState);

export default uiReducer;
