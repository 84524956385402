import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { UserState } from 'types';
import { StakingAbi } from 'types/contracts/StakingAbi';
import { PoolData } from 'types/store/staking';
import { AbiItem } from 'web3-utils';

import { getPools } from '../actions';
import actionTypes from '../actionTypes';
import { updateStakingState } from '../reducer';

export function* getPoolsSaga({ type, payload: { web3Provider } }: ReturnType<typeof getPools>) {
  yield* put(apiActions.request(type));
  const { address: userAddress, network, chainType }: UserState = yield select(userSelector.getUser);

  const { address: stakingContractAddress, abi: stakingAbi } =
    contractsConfig.contracts[ContractsNames.staking][chainType];

  const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(
    stakingAbi as AbiItem[],
    stakingContractAddress[network],
  );

  try {
    const totalPools = yield* call(stakingContract.methods.totalPools().call);
    const stakingData = yield* call(stakingContract.methods.getPoolsAndUserData(userAddress, 0, totalPools).call);

    if (stakingData[0].length) {
      const pools = stakingData[0];

      const activePoolsData = pools.reduce(
        (acc, pool, index) => {
          if (!pool[9]) {
            return [
              ...acc,
              {
                id: index,
                acceptStakesStart: pool[0],
                claimStart: pool[1],
                unstakeStart: pool[2],
                apr: +pool[3],
                totalStaked: pool[4],
                totalStakedCurrent: pool[5],
                totalRewardAmount: pool[6],
                currentRewardAmount: pool[7],
                claimAvailable: pool[8],
                cancelled: pool[9],
              },
            ];
          }
          return acc;
        },

        [] as PoolData[],
      );

      yield put(
        updateStakingState({
          pools: activePoolsData,
        }),
      );
    }

    console.log(stakingData);

    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_POOLS, getPoolsSaga);
}
