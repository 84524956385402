import { Fragment, lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { RoutesProps } from 'types';

import { formatRoutesToArr } from './formatRoutesToArr';

export default function getLazyRoutes(routes: RoutesProps, loggedIn: boolean, isStakingOwner: boolean) {
  const routesArr = formatRoutesToArr(routes).map(
    ({ dirName, root: { path, id, title, isProtected, module: configModule }, ...nested }) => {
      const Component = lazy(() =>
        import(`modules/${configModule}/pages/${dirName}`).then((module) => ({ default: module[dirName] })),
      );

      const isHomePage = path === '/';
      const isStakingPage = path === routes.staking.root.path;

      const route = (
        <Route
          path={path}
          element={
            (!isHomePage && isProtected && !loggedIn) || (isStakingPage && !isStakingOwner) ? (
              <Navigate to="/" />
            ) : (
              <Component title={title} />
            )
          }
          key={id}
        />
      );

      const hasNestedRoutes = Object.keys(nested).length;
      if (hasNestedRoutes) {
        return (
          <Fragment key={id}>
            {getLazyRoutes(nested, loggedIn, isStakingOwner)}
            {route}
          </Fragment>
        );
      }
      return route;
    },
  );
  return routesArr.flat();
}
