import { fork } from 'redux-saga/effects';

import approve from './approve';
import getCsvData from './getCsvData';
import getReport from './getReport';
import getTokenBalance from './getTokenBalance';
import login from './login';

export default function* userSagas() {
  yield fork(login);
  yield fork(getReport);
  yield fork(getCsvData);
  yield fork(approve);
  yield fork(getTokenBalance);
}
