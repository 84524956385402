const GET_POOLS = 'GET_POOLS' as const;
const CREATE_POOL = 'CREATE_POOL' as const;
const DEPOSIT_REWARD = 'DEPOSIT_REWARD' as const;
const DELETE_POOL = 'DELETE_POOL' as const;
const GET_STAKING_OWNER = 'GET_STAKING_OWNER' as const;

export default {
  GET_POOLS,
  CREATE_POOL,
  DEPOSIT_REWARD,
  DELETE_POOL,
  GET_STAKING_OWNER,
};
